/*!
Theme Name: CPC Parent
Version: 5.0.0
Author: github.com/hellorare
Template: cpc
*/

.primary-nav-wrapper {
	background-color: #e9e743;
}

.primary-nav .menu-item a {
	color: #4e4e4e;
}

.primary-nav .menu-item.active > a,
.primary-nav .menu-item:hover > a {
	color: #0079c2;
}

.primary-nav .menu-item.active > a:after,
.primary-nav .menu-item:hover > a:after {
	border-bottom-color: #0079c2;
}

.site-search-input-wrapper {
	background-color: #0079c2;
}

.google-translate {
	background-color: #4c9fd1;
}

.skoolbag-wrapper {
	background-image: url(images/bg-diagonals.png);
	background-color: #0079c2;
}

.cta-wrapper {
	background-color: #F6F6F6;
}

.cta:hover .cta-caption {
	background-color: #0079c2;
	color: #F6F6F6;
}

.cta:hover .cta-icon {
	color: #F6F6F6;
}

@media screen and (max-width: 880px) {

.site-search {
	background-color: #e9e743;
}

.site-search-input-wrapper {
	background-color: #e9e743;
}

}

