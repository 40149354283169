/*!
Theme Name: CPC Parent
Version: 5.0.0
Author: github.com/hellorare
Template: cpc
*/


// --------------------------------------------------------------------------
//   Variables
// --------------------------------------------------------------------------

// ----- Colours ----- //

$charcoal: 			#5d5d5d;
$darker-gray:		#4e4e4e;
$dark-gray:			#747474;
$medium-gray:		#e5e5e5;
$light-gray:		#F6F6F6;

$blue:					#0079c2;
$light-blue:		#4c9fd1;
$green:					#9c9c48;
$red:						#a9474b;
$yellow:				#e9e743;


// --------------------------------------------------------------------------
//   Navigation
// --------------------------------------------------------------------------

.primary-nav-wrapper {
	background-color: $yellow;
}

.primary-nav .menu-item a {
	color: $darker-gray;
}

.primary-nav .menu-item.active > a, .primary-nav .menu-item:hover > a {
	color: $blue;

	&:after {
		border-bottom-color: $blue;
	}
}



// --------------------------------------------------------------------------
//   Site Search
// --------------------------------------------------------------------------

.site-search {
	@media screen and (max-width: 880px) {
		background-color: $yellow;
	}
}

.site-search-input-wrapper {
	background-color: $blue;
}

.site-search-input-wrapper {
	@media screen and (max-width: 880px) {
		background-color: $yellow;
	}
}


// --------------------------------------------------------------------------
//   Translation Block
// --------------------------------------------------------------------------

.google-translate {
	background-color: $light-blue;
}


// --------------------------------------------------------------------------
//   Skoolbag
// --------------------------------------------------------------------------

.skoolbag-wrapper {
	background-image: url(images/bg-diagonals.png);
	background-color: $blue;
}


// --------------------------------------------------------------------------
//   CTA's
// --------------------------------------------------------------------------

.cta-wrapper {
	background-color: $light-gray;
}

.cta:hover {

	.cta-caption {
		background-color: $blue;
		color: $light-gray;
	}

	.cta-icon {
		color: $light-gray;
	}
}

